.contact-section {
  position: relative;
  padding-bottom: 0px !important;
  padding-top: 70px !important;
}

.contact-text {
  color: rgb(0, 0, 0);
}

.contact-social-links {
  justify-content: center !important;
  padding-top: 15px !important;
  display: inline-block !important;
  position: relative !important;
  padding-inline-start: 0 !important;
}

.contact-social-icons {
  position: relative !important;
  display: inline-block !important;
  width: 70px !important;
  height: 70px !important;
  text-align: center !important;
  font-size: 2em !important;
  line-height: 2em !important;
  background: rgba(0, 0, 0, 0.972) !important;
  border-radius: 50% !important;
  transition: 0.5s !important;
}

.contact-social-icons::before {
  content: "";

  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  background: #000000;
  transition: 0.5s;
  transform: scale(0.9);
  z-index: -1;
}

.contact-social-icons:hover::before {
  transform: scale(1.1);
  box-shadow: 0 0 15px #000000;
}

.contact-social-icons:hover {
  color: #ffffff;
  box-shadow: 0 0 7px #000000;
  text-shadow: 0 0 4px #000000;
}
.contact-icons {
  display: inline-block !important;
  padding-right: 15px;
  padding-left: 15px;
}

.icon-color {
  color: #ffffff !important;
}
